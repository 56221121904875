.navArrowsContainer {
  position: absolute;
}

.leftArrowCool {
  -webkit-appearance: none;
  padding: 0;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border: none;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: large;
  border-radius: 50%;
  color: var(--brand-cool-dark-text);
  background-color: var(--brand-cool-accent);
  font-weight: bold;
}

.rightArrowCool {
  -webkit-appearance: none;
  padding: 0;
  margin-left: 0.3rem;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border: none;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: large;
  color: var(--brand-cool-dark-text);
  background-color: var(--brand-cool-accent);
  border-radius: 50%;
  font-weight: bold;
}

.leftArrowWarm {
  -webkit-appearance: none;
  padding: 0;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border: none;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: large;
  border-radius: 50%;
  color: var(--brand-warm-dark-text);
  background-color: var(--brand-warm-accent);
  font-weight: bold;
}

.rightArrowWarm {
  -webkit-appearance: none;
  padding: 0;
  margin-left: 0.3rem;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border: none;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: large;
  color: var(--brand-warm-dark-text);
  background-color: var(--brand-warm-accent);
  border-radius: 50%;
  font-weight: bold;
}

/* Disable hover on mobile because it was leading to
buttons looking disabled when they were not */
@media (min-width: 992px) {
  .leftArrowWarm:hover,
  .leftArrowCool:hover,
  .rightArrowWarm:hover,
  .rightArrowCool:hover {
    opacity: 0.9;
    background-color: var(--brand-gray-hover);
  }
}

.leftArrowWarm:disabled,
.leftArrowCool:disabled,
.rightArrowWarm:disabled,
.rightArrowCool:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@-moz-document url-prefix() {
  .navArrowsContainer {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
}
