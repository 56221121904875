.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 60;
  background: rgba(75, 75, 75, 0.5);
  backdrop-filter: blur(24px);
}

.content {
  max-height: 50vh;
  overflow-y: hidden;
}

.modal {
  font-family: 'PT Root UI', sans-serif;
  font-weight: bold;
  position: fixed;
  top: 15vh;
  z-index: 100;
  background-color: rgba(244, 244, 248, 1);
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05);
  padding: 1.5rem;
  border-radius: 24px;
  left: calc(50% - 236px);
  width: 472px;
  max-height: 347px;
}

.modal .header {
  display: flex;
}

.modal .title {
  font-family: 'Londrina Solid';
  display: flex;
  flex-direction: column;
}

.modal .title h2 {
  font-size: 24px;
  margin-top: 0.5rem;
  color: rgba(140, 141, 146, 1);
}

.modal .title h1 {
  line-height: 42px;
  height: 2rem;
  font-size: 42px;
}

.closeBtnWrapper {
  padding: 1rem 2rem;
  display: flex;
  justify-content: flex-end;
}

.closeBtn {
  z-index: 100;
  position: fixed;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  transition: all 0.125s ease-in-out;
  border: 0;
}

.closeBtn:hover {
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
}

.closeBtn .icon {
  height: 24px;
  width: 24px;
}

@media (max-width: 992px) {
  .backdrop {
    background: rgba(0, 0, 0, 0.74);
  }

  .content {
    max-height: 100%;
    height: 100%;
  }

  .modal {
    top: auto;
    left: 0;
    bottom: 0;
    width: 100%;
    max-height: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: none;
  }
}
