.alpInfoHeader {
  display: inline-block;
  padding-left: 0rem;
  font-family: 'Londrina Solid';
}

@media only screen and (max-width: 600px) {
  .alpInfoHeader {
    padding-top: 1.5rem;
    margin-bottom: 0;
  }
}

.alpInfoHeader h2 {
  font-size: 56px;
  margin-bottom: 18px;
}

.alpInfoHeader h3 {
  font-size: 24px;
  margin-bottom: '0px';
  color: #79809c;
}

.alpInfoRow {
  font-family: 'PT Root UI';
  font-weight: 500;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  font-size: 18px;
  display: flex;
}

.alpInfoRowText {
  padding-left: 4;
  font-family: 'PT Root UI';
  font-family: bold;
}
