.date {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 17px;
  line-height: 27px;
  margin-top: 0.22rem;
}

.wrapper {
  margin-left: 5rem;
  width: auto;
}

@media (max-width: 568px) {
  .date {
    color: var(--brand-black) !important;
  }
}
