.shortAddress {
  display: flex;
  flex-flow: row nowrap;
  gap: 6px;
  align-items: center;
}

.shortAddress > div {
  padding-top: -2px;
}

.shortAddress span {
  letter-spacing: 0.2px;
  font-family: 'PT Root UI';
  font-weight: bold;
}

@media (max-width: 992px) {
  .mobileText {
    color: var(--brand-black) !important;
  }
}

@media (max-width: 568px) {
  .mobileText {
    color: var(--brand-black) !important;
  }
}
