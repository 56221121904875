.alpHolderEtherscanLinkCool {
  font-family: 'PT Root UI';
  font-weight: bold;
  color: var(--brand-color-blue);
  margin-left: 5px;
  text-decoration: none;
  transition: all 0.15s ease-in-out;
}

.alpHolderEtherscanLinkWarm {
  font-family: 'PT Root UI';
  font-weight: bold;
  color: var(--brand-color-red);
  margin-left: 5px;
  text-decoration: none;
  transition: all 0.15s ease-in-out;
}

.alpHolderEtherscanLinkWarm:hover {
  color: var(--brand-color-red) !important;
  filter: brightness(110%);
}

.alpHolderEtherscanLinkCool:hover {
  color: var(--brand-color-blue) !important;
  filter: brightness(110%);
}

.alpHolderInfoContainer {
  display: inline;
  width: 350px;
  height: 35px;
}

.alpHolderLoading {
  opacity: 0.5;
}

.linkIconSpan {
  margin-left: 5px;
}

.linkIcon {
  margin-bottom: 5px;
}

.heartIcon {
  margin-bottom: 4px;
  margin-right: 7px;
}

@media (max-width: 992px) {
  .mobileText {
    color: var(--brand-black) !important;
  }
}

@media (max-width: 568px) {
  .mobileText {
    color: var(--brand-black) !important;
  }

  .heartIcon {
    filter: brightness(0) invert(0) !important;
  }
}
