.wrapper {
  margin-left: auto;
  margin-right: auto;
  margin-top: -20px;
}

.alpCell {
  width: 55px;
  text-align: center;
}
