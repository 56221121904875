.wrapper {
  display: flex;
  flex-direction: column;
}

.stackedAlpWrapper {
  display: flex;
}

.address {
  font-family: 'Londrina Solid';
  font-size: 24px;
  width: 100%;
  text-align: left;
}

.alpsRepresented {
  color: var(--brand-gray-dark-text);
  display: flex;
  margin-top: 0.25rem;
  font-weight: 500;
  font-size: 15px;
  max-width: 11rem;
}

.bold {
  font-weight: bold;
}

.spinnerWrapper {
  color: var(--brand-gray-light-text);
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.spinner {
  display: flex;
  width: 100%;
  justify-content: center;
}

.icon {
  margin-bottom: 5px;
  margin-right: 6px;
}
