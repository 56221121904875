.wrapper {
  display: inline-block;
}

.wrapper h1 {
  font-family: 'Londrina Solid';
  font-size: 68px;
  margin-bottom: 10px;
}

@media (max-width: 992px) {
  .wrapper h1 {
    font-size: 56px;
  }

  .mobileText {
    color: var(--brand-black) !important ;
  }
}

@media (max-width: 568px) {
  .mobileText {
    color: var(--brand-black) !important ;
  }
}
