.alpInfoRowBirthday {
  font-family: 'PT Root UI';
  font-weight: bold;
  margin-left: 5px;
}

.birthdayInfoContainer {
  display: inline;
  width: 350px;
  height: 25px;
}

.birthdayIcon {
  margin-bottom: 4px;
  margin-right: 7px;
}

@media (max-width: 992px) {
  .classes.alpInfoRowBirthday {
    color: var(--brand-black) !important;
  }
  .mobileText {
    color: var(--brand-black) !important;
  }

  .birthdayIcon {
    filter: brightness(0) invert(0) !important;
  }
}

@media (max-width: 568px) {
  .classes.alpInfoRowBirthday {
    color: var(--brand-black) !important;
  }
  .mobileText {
    color: var(--brand-black) !important;
  }

  .birthdayIcon {
    filter: brightness(0) invert(0) !important;
  }
}
